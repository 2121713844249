@import '../../styles/variables';

.navLogo {
	display: flex;
	padding: 0 1rem;
	-webkit-tap-highlight-color: transparent;

	img {
		height: 2.85rem;
		object-fit: contain;
		width: 3.8rem;
	}

	.logoTitle {
		align-items: center;
		color: $white;
		display: flex;
		font-family: Raleway, sans-serif;
		font-size: 2.4rem;
		font-weight: 100;
		padding: 1rem;
	}
}