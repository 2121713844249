// Video Header component

import anime from 'animejs';
import { useEffect, useRef } from 'preact/hooks';
import { FaCaretDown } from 'react-icons/fa6';
import headerPoster from '../../static/images/video-posters/octo-header-poster.jpg.webp';
import useParallax from '../../hooks/useParallax.js';
import ScrollRevealer from '../ScrollRevealer/index.js';
import * as styles from './styles.module.scss';

const headerVideo = require('url:../../static/videos/octo-header.mp4');

export const VideoHeader = ({ children, heightClass, showHeader }) => {
	const scroll = () => {
		const mainContentWrapper = document.getElementById('main-content-start');
		window.scrollTo({top: mainContentWrapper.getBoundingClientRect().top + window.scrollY -20, behavior: 'smooth'});
	};

	const getHeightClass = (heightClassString) => {
		if(heightClass === 'full') {
			return styles.headerFull;
		}
		else {
			return styles.headerHalf;
		}
	};

	const scrollButtonRef = useRef();

	useEffect(() => {
		anime({
			targets: scrollButtonRef.current,
			opacity: 1,
			duration: 1000,
			delay: 2750
		})
	}, []);

	const headerRef = useParallax();

	return (
		showHeader && (
			<div ref={headerRef} class={[styles.header, !showHeader ? styles.hideHeader : null, getHeightClass(heightClass)].join(' ')}>
				<>
					<VideoBackground video={headerVideo} poster={headerPoster} />

					<div class={styles.headerContent}>
						<ScrollRevealer immediate={true} delay={250} duration={1750}>
							<h1 class={styles.headerTitle}>NeuroAnimation</h1>
						</ScrollRevealer>

						<ScrollRevealer immediate={true} delay={500} duration={1750}>
							<h2 class={styles.headerSubTitle}>Move beautifully.</h2>
						</ScrollRevealer>

						<ScrollRevealer immediate={true} delay={750} duration={1750}>
							<h3 class={styles.headerTagline}>Movement Experiences for Stroke Therapy, Mind-Body Health, and Gaming</h3>
						</ScrollRevealer>

						<div class={styles.scrollButtonContainer}>
							<button ref={scrollButtonRef} aria-label={'Scroll to content'} title={'Scroll to content'} class={styles.scrollButton} onClick={scroll}>
								<FaCaretDown />
							</button>
						</div>
					</div>
				</>

				{ children }
			</div>
		)
	);
};

const VideoBackground = ({ poster, video }) => (
	<div class={styles.videoBackgroundWrapper}>
		<video autoPlay={true} poster={poster} loop muted playsInline>
			<source src={video} type='video/mp4' />
		</video>

		<div class={styles.videoShadow}></div>
	</div>
);
